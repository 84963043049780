<template>
  <div>
    <div class="card">
      <div class="card-body form-card">
        <div class="row">
          <div class="col-4">
            <el-input v-model="form.sync_user_id" placeholder="User ID"></el-input>
          </div>
          <div class="col-4">
            <el-input v-model="form.sync_activity_id" placeholder="Activity ID"></el-input>
          </div>
          <div class="col-4">
            <el-button class="full-width" type="info" icon="el-icon-refresh" @click="syncActivity">
              Đồng bộ
            </el-button>
          </div>
        </div>

      </div>
    </div>

    <div class="card">
      <div class="card-body form-card">
        <div class="row">
          <div class="col-3">
            <el-input v-model="form.user_id" placeholder="User ID"></el-input>
          </div>
          <div class="col-3">
            <el-input v-model="form.strava_id" placeholder="Strava ID"></el-input>
          </div>
          <div class="col-3">
            <el-input v-model="form.activity_id" placeholder="Activity ID"></el-input>
          </div>
          <div class="col-3">
            <el-button class="full-width" type="primary" icon="el-icon-info" @click="viewUserInfo">
              Xem thông tin
            </el-button>
          </div>
        </div>

        <div class="user-info" v-if="isShowUserInfo">

          <div class="row">
            <div class="col-2">
              <label>Thông tin User</label>
            </div>
            <div class="col-10">
              <table class="table table-bordered">
                <tr>
                  <td>User ID</td>
                  <td><a :href="'https://irace.vn/races/users/' + user.user.id" target="_blank">{{ user.user.id }}</a></td>
                </tr>
                <tr>
                  <td>Thông tin</td>
                  <td>{{ user.user.name }}</span> - <span>{{ user.user.email }}</span> - <span>{{ user.user.phone }}</span></td>
                </tr>
                <tr>
                  <td>Ngày tạo tài khoản</td>
                  <td>{{ user.user.created_at }}</td>
                </tr>
                <tr>
                  <td>Ứng dụng đã kết nối</td>
                  <td>{{ user.app || 'Chưa kết nối ứng dụng nào' }}</td>
                </tr>
                <tr>
                  <td colspan="2">
                    <el-button size="medium" type="danger" icon="el-icon-refresh" @click="syncAllActivities(user.user.id)">Đồng bộ 200 hoạt động mới nhất từ ngày 01/11</el-button>
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <div class="row">
            <div class="col-2">
              <label>Strava</label>
            </div>
            <div class="col-10">
              <table class="table table-bordered">
                <tr>
                  <td>Strava ID</td>
                  <td><a :href="'https://www.strava.com/athletes/' + user.user.strava_id" target="_blank"> {{ user.user.strava_id }}</a></td>
                </tr>
                <tr>
                  <td>Kết nối lần cuối</td>
                  <td>{{ user.user.strava_connected_at }}</td>
                </tr>
                <tr>
                  <td>Kết nối lần đầu</td>
                  <td>{{ user.user.strava_first_connected_at }}</td>
                </tr>
              </table>
            </div>
          </div>

          <div class="row">
            <div class="col-2">
              <label>Login BIDV</label>
            </div>
            <div class="col-10">
              <el-table
                :data="user.bidv_user"
                border
                style="width: 100%">
                <el-table-column
                  prop="created_at"
                  label="Thời gian"
                >
                </el-table-column>
                <el-table-column
                  prop="strava_id"
                  label="Strava ID"
                >
                </el-table-column>
                <el-table-column
                  prop="get_success_at"
                  label="BIDV lấy thông tin"
                >
                </el-table-column>
              </el-table>
            </div>
          </div>

          <div class="row">
            <div class="col-2">
              <label>Danh sách hoạt động</label>
            </div>
            <div class="col-10">
              <el-table
                :data="user.queues"
                border
                max-height="400"
                style="width: 100%">

                <el-table-column
                  prop="id"
                  label="Activity ID"
                  width="150"
                >
                  <template slot-scope="scope">
                    <a :href="'https://www.strava.com/activities/' + scope.row.object_id" target="_blank">{{ scope.row.object_id }}</a>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="created_at"
                  label="Thời gian"
                  width="150"
                >
                </el-table-column>

                <el-table-column
                  prop="sync"
                  label="Trạng thái"
                  width="150"
                >
                  <template slot-scope="scope">
                    <badge v-if="scope.row.sync == 1" type="success">Đã check</badge>
                    <badge v-else-if="scope.row.sync == 0" type="default">Chưa sync</badge>
                    <badge v-else-if="scope.row.sync == 2" type="danger">Sync lỗi</badge>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="irace_sync"
                  label="iRace Đồng bộ"
                  width="150"
                >
                  <template slot-scope="scope">
                    <badge v-if="scope.row.irace_sync == 1" type="success">Đã đồng bộ</badge>
                    <badge v-else-if="scope.row.irace_sync == 0" type="default">Chưa đồng bộ</badge>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="bidv_sync"
                  label="BIDV Đồng bộ"
                  width="200"
                >
                  <template slot-scope="scope">
                    <div v-if="scope.row.bidv_sync">
                      <div v-if="scope.row.bidv_sync.push_success_at">
                        <badge type="success">PUSH - {{ scope.row.bidv_sync.push_success_at }}</badge>
                      </div>
                      <div v-if="scope.row.bidv_sync.get_success_at">
                        <badge type="warning">GET - {{ scope.row.bidv_sync.get_success_at }}</badge>
                      </div>
                      <badge v-else type="danger">CHƯA GET</badge>
                      <div>
                        <el-button size="small" type="info" icon="el-icon-refresh" @click="rePushToBIDV(scope.row.bidv_sync.id)">Push Again</el-button>
                      </div>
                    </div>
                    <!--
                    <div v-else-if="scope.row.irace_sync == 1">
                      <el-button class="full-width" size="small" type="info" icon="el-icon-upload" @click="pushToBIDV(scope.row.object_id)">PUSH TO BIDV</el-button>
                    </div>
                    -->
                  </template>
                </el-table-column>

                <el-table-column
                  prop="logs"
                  label="Logs"
                >
                <template slot-scope="scope">
                  {{ scope.row.status }}
                  <span v-if="scope.row.logs"> - {{ scope.row.logs }}</span>
                </template>
                </el-table-column>
              </el-table>
            </div>
          </div>

        </div>

      </div>
    </div>

    <loading :active.sync="isLoading"></loading>

  </div>
</template>

<script>
  import { Message, Button, Input, Table, TableColumn, MessageBox } from 'element-ui';
  import Loading from 'vue-loading-overlay';
  import Badge from 'src/components/UIComponents/Badge.vue'
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    components: {
      Message,
      Loading,
      Badge,
      MessageBox,
      ElButton: Button,
      ElInput: Input,
      ElTable: Table,
      ElTableColumn: TableColumn
    },

    data() {
      return {
        form: {},
        user: {
          user: {},
          app: '',
          queues: [],
          bidv_user: []
        },
        isLoading: false,
        isShowUserInfo: false
      }
    },

    methods: {

      rePushToBIDV(bidv_activity_id) {
        MessageBox.confirm('Push lại hoạt động này sang BIDV?', 'Warning', {
          confirmButtonText: 'Đồng ý',
          cancelButtonText: 'Hủy bỏ',
          type: 'warning',
          center: true
        }).then(_ => {
          this.isLoading = true;
          var obj = { bidv_activity_id };
          this.$store.dispatch('pushActivityToBIDV', obj).then(res => {
            this.isLoading = false;
            if (res.success) {
              Message({
                type: 'success',
                message: res.message
              });
            } else {
              Message({
                type: 'error',
                message: res.message
              });
            }
          });
        });
      },

      syncActivity() {
        if (!this.form.sync_user_id) {
          return Message({
            type: 'error',
            message: 'Chưa nhập User ID'
          });
        }

        let obj = {
          user_id: this.form.sync_user_id,
          activity_id: this.form.sync_activity_id
        };

        this.isLoading = true;
        this.$store.dispatch('syncUserActivities', obj).then(res => {
          this.isLoading = false;
          if (res.success) {
            Message({
              type: 'success',
              message: res.message
            });
          } else {
            Message({
              type: 'error',
              message: res.message
            });
          }
        });
      },

      syncAllActivities(user_id) {
        MessageBox.confirm('Đồng bộ tất cả hoạt động?', 'Warning', {
          confirmButtonText: 'Đồng ý',
          cancelButtonText: 'Hủy bỏ',
          type: 'warning',
          center: true
        }).then(_ => {
          var obj = {
            user_id: user_id,
            from: '2020-11-01 00:00:00'
          };
          this.isLoading = true;
          this.$store.dispatch('syncUserAllActivities', obj).then(res => {
            this.isLoading = false;
            if (res.success) {
              Message({
                type: 'success',
                message: res.message
              });
            } else {
              Message({
                type: 'error',
                message: res.message
              });
            }
          });
        });
      },

      pushToBIDV(activity_id) {
        let user_id = this.user.user.id;
        var obj = { user_id, activity_id };
        alert("Đang cập nhật");
      },

      viewUserInfo() {
        if (!this.form.user_id && !this.form.strava_id && !this.form.activity_id) {
          return Message({
            type: 'error',
            message: 'Chưa nhập thông tin để kiểm tra'
          });
        }

        var obj = {
          user_id: this.form.user_id,
          strava_id: this.form.strava_id,
          activity_id: this.form.activity_id
        }

        this.isLoading = true;
        this.isShowUserInfo = false;
        this.$store.dispatch('getUserInfoBIDV', obj).then(res => {
          this.isLoading = false;
          if (res.success) {
            this.user = Object.assign({}, res.data);
            this.isShowUserInfo = true;
          } else {
            Message({
              type: 'error',
              message: res.message
            });
          }
        });

      }

    },

    mounted() {
      window.AP = this;
      this.$store.dispatch('setPageTitle', 'Support Tool BIDV');
      this.$store.dispatch('setCurrentFilterUsers', []);
      this.$store.dispatch('setCurrentActions', []);
    },

    destroyed() {
      this.$store.dispatch('setCurrentActions', []);
    }
  }

</script>
<style lang="scss" scoped>
  .el-table .td-actions {
    button.btn {
      margin-right: 5px;
    }
  }

  .filter-group-bill {
    padding: 15px;
  }

  .import-status {
    width: 60%;
    margin: 20px auto 0;
  }

  .user-info {
    padding: 20px 0;
    div {
      margin-bottom: 5px;
    }
  }

</style>
